



















import RegisterForm from "@/components/registration/RegisterForm.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
	components: { RegisterForm },
})
export default class Registration extends Vue {}
