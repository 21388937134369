




















































































import { Component, Vue } from "vue-property-decorator";
import PrimaryButton from "@/components/general/PrimaryButton.vue";
import InputText from "@/components/general/InputText.vue";
import InputDropdown from "@/components/general/InputDropdown.vue";
import Modal from "@/components/Modal.vue";
import Axios from "axios";

@Component({
	components: { PrimaryButton, InputText, InputDropdown, Modal },
})
export default class RegisterForm extends Vue {
	faculties = [
		{
			label: "Fakultät HW",
			options: ["Bildungswissenschaften", "BWL", "Edutech", "Fachdidaktik (Chemie)", "Fachdidaktik (Germanistik)", "Psychologie", "Sportwissenschaften", "Sonstige"],
		},
		{
			label: "Fakultät M",
			options: ["Medizin"],
		},
		{
			label: "Fakultät MI",
			options: ["DFKI", "Informatik", "Mathematik", "Sonstige"],
		},
		{
			label: "Fakultät NT",
			options: ["Biologie", "Chemie", "Material- und Werkstoffwissenschaften", "Pharmazie", "Physik", "Systembiotechnologie", "Sonstige"],
		},
		{
			label: "Fakultät P",
			options: [
				"Allg. und vgl. Literaturwissenschaften",
				"Alte Geschichte",
				"Altertumswissenschaften",
				"Anglistik",
				"Computerlinguistik",
				"Ev. Theologie",
				"Geographie",
				"Germanistik",
				"Geschichte",
				"HoK",
				"Kath. Theologie",
				"Kunst- und Kulturwissenschaften",
				"Optionalbereich",
				"Philologie",
				"Philosophie",
				"Romanistik",
				"Sprachwissenschaften & -technologie",
				"Sonstige",
			],
		},
		{
			label: "Fakultät R",
			options: ["Rechtswissenschaften", "Sonstige"],
		},
		{
			label: "Zentrale Einrichtungen / Institute",
			options: ["Antirassismus", "AS Hochschuldidaktik", "AS Schlüsselkompetenzen", "CEC", "CEUS", "Eur. Traumkulturen", "Gradus", "GraduSaar", "ISZSaar", "PROWIS", "Sprachenzentrum", "Sonstige"],
		},
	];
	courseTypes = [
		{
			label: "Vorlesung",
			options: ["Standard", "mit Tutorium"],
		},
		{
			label: "Seminar",
			options: ["Standard", "mit Arbeitsaufträgen", "mit Referaten", "mit Arbeitsaufträgen und Referaten"],
		},
		{
			label: "Sonstige",
			options: ["Praktikum", "Sprachpraxisübung", "Tutorium", "Übung", "Workshop"],
		},
	];
	course = {
		medium: "online",
		mail: "",
		dep: "---",
		first: "",
		honor: "",
		last: "",
		length: "",
		lsf: "",
		title: "",
		type: "---",
		notes: "",
		count: 0,
	};
	modalSettings = {
		header: "",
		content: "",
		open: false,
	};
	logIt(): void {
		const uploadCourse = { ...this.course };
		if (uploadCourse.lsf.trim() === "") uploadCourse.lsf = "99";
		Axios.post("assets/php/handleRegistrations.php", JSON.stringify({ call: "addRegistration", payload: uploadCourse }))
			.then(response => {
				if (+response.data === 1) this.saveSuccess();
				else this.saveError();
			})
			.catch(() => this.serverError());
	}
	saveSuccess(): void {
		this.modalSettings.header = "Daten erfolgreich übertragen";
		this.modalSettings.content = this.generateModalContent();
		this.modalSettings.open = true;
		this.course.title = "";
		this.course.notes = "";
	}
	saveError(): void {
		this.modalSettings.header = "Fehler bei der Datenübertragung";
		this.modalSettings.content = "Leider ist bei der Übertragung Ihrer Daten ein Fehler aufgetreten. Falls dieses Probleme wiederholt auftritt, kontaktieren Sie bitte qualis@mx.uni-saarland.de";
		this.modalSettings.open = true;
	}
	serverError(): void {
		this.modalSettings.header = "Fehler bei der Kommunikation mit dem Server";
		this.modalSettings.content = "Leider ist ein Fehler bei der Kommunikation mit dem Server aufgetreten. Falls dieses Probleme wiederholt auftritt, kontaktieren Sie bitte qualis@mx.uni-saarland.de";
		this.modalSettings.open = true;
	}
	generateModalContent(): string {
		return (
			"<p>Die Daten zu Ihrer Evaluation wurden erfolgreich gespeichert</p>" +
			"<h2 class='font-bold'>Zusammenfassung</h2>" +
			`<ul><li>Titel der Veranstaltung: ${this.course.title}</li>` +
			(this.course.lsf.trim() !== "" ? `<li>LSF Nummer: ${this.course.lsf}</li>` : "") +
			`<li>Evaluationsmodus: ${this.course.medium}</li>` +
			`<li>Lehrperson(en): ${this.course.honor.trim()} ${this.course.first.trim()} ${this.course.last.trim()}</li>` +
			`<li>Fachrichtung: ${this.course.dep}</li>` +
			`<li>Art des Bogens: ${this.course.type}</li></ul>`
		);
	}
}
